.list__header {
  display: flex;
  justify-content: space-between;

  h2 {
    margin: 24px 0;
  }

  h4 {
    margin-bottom: 0;
  }
}

.list__buttons {
  display: flex;
  align-items: center;
}
