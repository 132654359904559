@import '../../resources/scss/utils/variables';

.tenant-overview {
  .tenant-overview__details {
    display: flex;
    justify-content: space-between;

    p {
      color: black;
    }

    h6 {
      color: $colorPrimary;
    }
  }
}
