@import '../../resources/scss/utils/variables';

.job-overview {
  .job-overview__details {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 16px;
      &:first-child {
        color: $colorPrimary;
      }
      &:last-child {
        color: black;
      }
    }
  }
}
