body:has(.player-wrapper) { 
  overflow: hidden; 
}

.main {
  height: 100vh;
  overflow: hidden;
  z-index: 4;
}
  
.back_button {
  height: 37px;
  width: 37px;
}
  
.center {
  --SliderColor: transparent;
  position: absolute;
  inset: 0;
  margin: auto;
}

.showControls {
  position: absolute;
  inset: 0;
}

.player {
  position: absolute;
  inset: 0px;
  margin: auto;
}

.bottom_0 {
  position: absolute;
  bottom: 0;
}

.progress_bar {
  -webkit-appearance: progress;
  outline: none;
  border-radius: 8px;
  height: 8px;
  cursor: default;
}

.progress_bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: transparent;
  overflow: visible;
  cursor: pointer;
}

.z_index_4 {
  z-index: 4;
}