$colorWhite: #ffffff;

$colorPrimary: #f95047;
$colorSecondary: #f5f5f5;
$colorTertiary: #999999;
$colorDanger: #ff4140;
$colorGreyDark: #606060;
$colorBlack: #000000;

$outlineColor: #999999;
$outlineColorActive: #f95047;

$colorChart0: #5899da;
$colorChart1: #e8743b;
$colorChart2: #19a979;
$colorChart3: #ed4a7b;
$colorChart4: #945ecf;
$colorChart5: #13a4b4;
$colorChart6: #525df4;
$colorChart7: #bf399e;
$colorChart8: #6c8893;
$colorChart9: #ee6868;
