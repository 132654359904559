@import '../../globals';

.sign-in__header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $colorPrimary;

  img {
    width: 270px;
  }
}
