/* import the necessary Bootstrap files */
// @import "~bootstrap/scss/functions";

@import 'globals';

$theme-colors: (
  'primary': $colorPrimary,
  'chart0': $colorChart0,
  'chart1': $colorChart1,
  'chart2': $colorChart2,
  'chart3': $colorChart3,
  'chart4': $colorChart4,
  'chart5': $colorChart5,
  'chart6': $colorChart6,
  'chart7': $colorChart7,
  'chart8': $colorChart8,
  'chart9': $colorChart9,
  'grey-dark': $colorGreyDark,
  'black': $colorBlack,
);

 @import '~bootstrap/scss/bootstrap';

@font-face {
  font-family: 'Roboto';
    src: url('./resources/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

 @font-face {
   font-family: 'Roboto';
     src: url('./resources/fonts/Roboto-Bold.ttf') format('truetype');
   font-weight: bold;
   font-style: normal;
 }

*,
:after,
:before {
  box-sizing: border-box;
}

body .App {
  margin: 0;
  font-family: Roboto;
  color: $colorBlack;

  a {
    color: $colorBlack;
  } 
  
  a .active {
    color: $colorPrimary; 
  }

  .nav-link {
    color: $colorBlack;
  }

  .nav-link.active {
    color: $colorPrimary;
    font-weight: bold;
  }

  .card-header {
    font-weight: bold;
  }
}

label {
  color: $colorBlack;
}

.text-left {
  text-align: left;
}

.text-center {
  color: $colorBlack;
  text-align: center;
}

.text-right {
  text-align: right;
}

.w-full {
  width: 100%;
}

.card,
.crud {
  background-color: $colorPrimary;
}

.btn-group {
  .btn {
    margin-left: 5px;
  }
}

label {
  display: block;
}

label.checkbox {
  display: inline;
}

input:not([type='checkbox']):not([type='search']):not([type='radio']),
textarea,
select {
  background-color: $colorWhite;
  border-radius: 0;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 0px 10px 0px;
}

input:not([type='checkbox']):not([type='search']):not([type='radio']):not([type='range']),
textarea {
  appearance: none;
}

.form-check-input[che] + label {
  color: $colorBlack;
}

.form-check-label {
  padding-right: 3px;
}

.form__field-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  flex-direction: column;

  > div {
    padding: 0 20px;
  }
}

.page-title {
  color: $colorBlack;
  margin: 24px 0;
  font-weight: bold;
}

table {
  border-collapse: collapse;
  position: relative;
  height: 100%;
  width: 100%;
}

th {
  position: sticky;
  top: 60px;
  font-weight: bold;
  text-align: left;
  padding: 1rem;
  font-size: 15px;
  background-color: $colorPrimary;
  color: $colorWhite;
  z-index: 1;
}

thead tr {
  background-color: #292c34;
}

td {
  padding: 1rem;
  font-weight: normal;
  text-align: left;
  color: $colorBlack;
  font-size: 15px;
  border-color: $colorSecondary;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

td, th {
  border-width: 2px !important;
}

tr:nth-child(odd) {
  background-color: #fdece9;
}

tr:hover {
  td, td button {
    font-weight: bold;
  }
}

label.required {
  &::after {
    content: '*';
    color: $colorDanger;
  }
}

.full-size-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.white-opacity {
  background-color: rgba(255, 255, 255, 0.7);
}

.border-grey {
  border-color: rgba(0, 0, 0, 0.125);
}

.box-gray-shadow {
  box-shadow: 0px 0px 10px #cfd8dc;
}

.cursor-pointer {
  cursor: pointer;
}

.piecharts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.list-body {
  overflow-x: auto;

  table {
    min-width: max-content;
    width: 100%;
  }
}

.disabled {
  cursor: no-drop;
}