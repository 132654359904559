@import '../../../globals';

.overlay {
  z-index: 98;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.app-modal {
  z-index: 99;
  position: relative;
  width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.app-modal__body {
  color: $colorPrimary;
  padding: 20px 24px;
  min-height: 200px;
  background-color: $colorWhite;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
