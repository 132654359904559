@import 'resources/scss/utils/variables';
@import 'resources/scss/utils/mixins';

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.container-full {
  margin: 0 auto;
  padding: 0 20px;
}