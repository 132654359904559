@import '../../globals';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.navigation {
  min-height: 60px;
  background-color: $colorWhite;
  position: sticky;
  z-index: 98;
}

th {
  position: sticky;
  top: 0;
}

.navigation__container {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation__logo-container {
  display: flex;
  align-items: center;

  .navigation__logo {
    width: 220px;
  }
}

.navigation__menu-button {
  display: inline-block;
  cursor: pointer;
}

.navigationbar__menu {
  height: 100vh;
  align-self: flex-start;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;
  width: 250px;
  background-color: #D9D9D9;

  a {
    position: relative;
    margin-left: 10px;
    font-size: 16px;
    text-align: left;
    display: block;
    color: $colorPrimary;
    font-weight: 900;
    text-decoration: none;
  }

  a:hover {
    color: #000;
  }

  a::before {
    content: "";
    position: absolute;
    display: block;
    width: 80%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  a:hover::before {
    transform: scaleX(1);
  }

  a.active {
    color: $colorPrimary;
  }
}

.admin-user-container {
  position: sticky;
  left: 0;
  width: 180px;
}

.navigation__logout-desktop {
  display: inline-block;
}

.navigation__user-details {
  font-size: small;
}

.navigation_bottom_div {
  position: absolute;
  bottom: 0;
  margin: 0 10px 10px 10px; 
}

.navigation__logout-desktop-icon {
  padding-top: 4px;
}

.navigation__logout {
  width: 100%;
  height: 36px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
    outline: 0px;
  }

  svg {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 2px;
  }
}

.App .navigation__menu {
  a {
    background-color: $colorPrimary;
    color: $colorWhite;
    display: block;
    padding: 10px 20px;
    border-bottom: 1px solid $colorWhite;
    text-decoration: none;
    transition: 0.3s;

    &:hover,
    &:active,
    &:focus {
      opacity: 0.8;
      outline: 0px;
    }
  }
}

.side-nav {
  display: flex;
}

.navigation-icon {
  margin-right: 10px;
}

@include media(md) {
  .navigation__menu {
    display: none;
  }

  .navigation__menu-button {
    display: none;
  }

  .navigationbar__menu {
    display: block;
  }

  .navigation__menu-button {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .navigationbar__menu {
    display: none;
  }

  .navigation__menu {
    display: block;
  }

  .navigation__menu-button {
    display: block;
  }

  .navigation__logout-desktop {
    display: none;
  }
}