@import '../../../globals';

.app-btn {
  color: $colorWhite;
  text-decoration: none;
  cursor: pointer;
  border: none;

  &-primary {
    background-color: $colorPrimary;
  }

  &-secondary {
    background-color: $colorTertiary;
  }

  &-danger {
    background-color: $colorDanger;
  }
 
  &-sm {
    padding: 0.25rem 0.5rem;
  }

  &-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.btn[disabled=disabled], .btn:disabled {
  opacity: 0.5;
  cursor: initial;
  pointer-events: none;
}

.app-icon-btn {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover, &:active, &:focus {
    opacity: 0.7;
    outline: 0px;
  }
}
